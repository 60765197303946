<template>
  <div data-app>
    <v-row>
      <v-col md="9" offset-xl="2" xl="6">
        <v-row class="mt-5">
          <v-col cols="8">
            <label>Power Brand</label>
          </v-col>
        </v-row>

        <v-divider class="mt-5 mb-5"></v-divider>

        <v-treeview :items="powerBrandsTree">
          <template v-slot:append="{ item }">
            <template>
              <v-btn-toggle dense style="background-color: transparent">
                <v-btn class="edit-btn" @click="edit(item)"
                  ><i class="flaticon2-edit"></i
                ></v-btn>
              </v-btn-toggle>
            </template>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module";
import { POWER_BRANDS } from "@/core/services/store/power_brands.module";

export default {
  data: () => ({
    powerBrands: [],
    powerBrandsTree: [],
  }),

  created() {
    this.loading = true;
  },

  methods: {
    fetchpowerBrands() {
      this.$store.dispatch(POWER_BRANDS).then((data) => {
        this.powerBrands = data.pages;
        this.powerBrandsTree = [];
        this.powerBrands.forEach((item) => {
          this.powerBrandsTree.push({
            id: item.id,
            name: item.page_title,
            children: [],
          });
        });

        this.loading = false;
      });
    },

    edit(item) {
      this.$router.push({
        name: "modifica-power-brand",
        params: {
          id: item.id,
          name: item.name || "",
        },
      });
    },
  },

  mounted() {
    this.fetchpowerBrands();
  },

  watch: {
    loading(newVal) {
      if (newVal) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      } else {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }
    },
  },
};
</script>